import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useLocation } from "react-router-dom";
import * as HTTPManager from "./utils/HTTPManager";

const ShareMemo = (props) => {
  const { pathname } = useLocation();
  const shareCode = pathname.split('/')[1];
  const [memoInfo, setMemoInfo] = useState("");
  const [memoContent, setMemoContent] = useState([]);
  const [title, setTitle] = useState("코드 확인 중...");

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let res = await HTTPManager.getShareMemo({
      code: shareCode,
    });

    if (res !== undefined && res.data !== undefined) {
      // let logList = res.data.result;
      // // 최신순으로 정렬
      // logList.sort((a, b) => {
      //   if (new Date(a.timestamp.replace(" ", "T")) > new Date(b.timestamp.replace(" ", "T")))
      //     return -1;
      //   if (new Date(a.timestamp.replace(" ", "T")) < new Date(b.timestamp.replace(" ", "T")))
      //     return 1;
      // })

      if (res.data.result !== undefined && res.data.result.length > 0) {
        setMemoInfo(res.data.result);
        setMemoContent(JSON.parse(res.data.result[0].content));
      } else {
        setTitle("코드를 다시 확인해주세요!");
      }
    }
  }


  return (
    <div style={{ display: 'flex', height: "100%", width: "100%", justifyContent: "center", alignItems: "center", }}>
      {
        memoInfo === "" ?
          <div style={{ display: 'flex', height: '100vh', width: '100%' }}>{title}</div> :
          <Grid container justifyContent="center" alignItems="center" style={{}}>
            <List sx={{}}>
              {
                memoContent.map((item, index) => {
                  if (item.uid !== 0) {
                    return <div key={index}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={item.uid}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary">
                                {item.content}
                              </Typography>
                              {" — "} {item.createdAt}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </div>
                  } else {
                    return null
                  }
                })
              }
            </List>
          </Grid>
      }
    </div>
  );
}

export default ShareMemo;