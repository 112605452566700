import React from 'react';

const Main = (props) => {
  return (
    <div style={{ display: 'flex', height: "100vh", width: "100%", justifyContent: "center", alignItems: "center", }}>
      <h3>간편 메모(.feat 대화형)</h3>
    </div>
  );
}

export default Main;