import './App.css';
import * as React from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import Main from './Main';
import ShareMemo from './ShareMemo';

function App() {
  const url = window.location.href;
  const location = window.location;

  return (
    <div style={{ display: 'flex', height: "100%", width: "100%", }}>
      <Router>
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/:id" element={<ShareMemo />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
